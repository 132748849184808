import Post from "../post/Post"
import "./posts.css"

export default function Posts() {
  return (
    <div className="posts">
        <Post />
    </div>
  )
}
